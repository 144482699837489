jQuery(document).on('click', 'button#send-query', function(e) {
    e.preventDefault();
    jQuery.ajax({
        type:'POST',
        url:'/contact/sendData',
        data:jQuery("form#ajax-contact-frm").serialize(),
        dataType:'json',    
        beforeSend: function () {
            jQuery('button#send-query').addClass('loading');
        },
        complete: function () {
            setTimeout(function () {
                jQuery('span#success-msg').css('display', 'none');
            }, 10000);
        },                
        success: function (json) {           
           $('.text-danger').remove();
            if (json['error']) {
             
                for (i in json['error']) {
                    var element = $('.input-acf-' + i.replace('_', '-'));
    
                    if($('.widget_form').hasClass('lang_fr')) {
                        $(element).after('<span class="text-danger">' + json['erreur'][i] + '</span>');
                    } else {
                        $(element).after('<span class="text-danger">' + json['error'][i] + '</span>');
                    }
                }
            } else {
                jQuery('span#success-msg').css('display', 'block');
                jQuery("form#ajax-contact-frm").find('textarea, input').each(function () {
                    jQuery(this).val('');
                });
            }                       
        },
        error: function (xhr, ajaxOptions, thrownError) {
            console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
        }        
    });
    return false;
});