// Display info of 1st item in the carousel
carouselInfo = function() {
    var firstItem = $('.owl-carousel__item').first().data('text');
    $('.carousel-info').html(firstItem);
}

$(window).on('load', carouselInfo);

// Set margin-left to carousel
window.carouselOffset = function() {
    var textAnchor = $('.offset-anchor-text');
    if(textAnchor.length > 0) {
        var offsetLeft = textAnchor.offset().left;
        var widgetCarousel = $('.widget_carousel');
        widgetCarousel.css('marginLeft', offsetLeft);
    }
}

$(window).on('load', carouselOffset);

// Set padding-left to feed images
window.feedImagesOffset = function() {
    var textAnchor = $('.offset-anchor-text');
    if(textAnchor.length > 0) {
        var offsetLeft = textAnchor.offset().left;
        var widgetFeedImages = $('#trainings_brands_feed');
        widgetFeedImages.css('paddingLeft', offsetLeft);
    }
}

$(window).on('load', feedImagesOffset);
$(window).on('resize', window.feedImagesOffset);

// Remove .owl-carousel in mobile
window.carouselMobile = function() {
    if($(window).width() < 601) {
        if($('.trainings-carousel').hasClass('owl-loaded')) {
            $('.trainings-carousel').owlCarousel('destroy');
        }
    } else {
        if(!$('.trainings-carousel').hasClass('owl-loaded')) {
            init_carousel();
        }
    }
}

var resizeTimer;

$(window).on('resize', function(e) {

    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
        carouselMobile();
    }, 500);
});

// Remove reveal animation if in mobile
window.carouselReveal = function() {
    if($(window).width() < 601) {
        if($('.widget_carousel').hasClass('reveal_this')) {
            $('.widget_carousel').removeClass('reveal_this');
        }
    } else {
        if(!$('.widget_carousel').hasClass('reveal_this')) {
            $('.widget_carousel').addClass('reveal_this');
        }
    }
}

$(window).on('load', carouselReveal);

// Toggle collapse (carousel mobile)
$('.owl-carousel__item').each(function () {
    var $this = $(this);
    $this.on("click", function () {
        if($(window).width() < 601) {
            if($this.hasClass('show')) {
                $this.removeClass('show');
                $this.children('.collapse-content').removeClass('show');
            } else {
                $('.owl-carousel__item').removeClass('show');
                $('.collapse-content').removeClass('show');
                $this.addClass('show');
                $this.children('.collapse-content').addClass('show');
            }
        } else {
            return false;
        }
    });
});

// FORM ANIMATION
var openFormBtn = $('#formations_hero .cta a');

openFormBtn.on('click', (e) => {
    e.preventDefault();
    // openFormBtn.attr('href', "javascript: void(0)"); // disable link
    $('.widget_form').toggleClass('show');

    return false;
});