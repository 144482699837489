popupDisplay = function() {
    if (sessionStorage.getItem('displayed') !== 'true') {
        var popup = $('.widget_pop-up');
        var closeBtn = $('.widget_pop-up__closeBtn');
    
        setTimeout(function() { popup.addClass('active'); }, 4600);

        closeBtn.click(function() {
            popup.removeClass('active');
            setTimeout(function() { popup.css('visibility', 'hidden'); }, 500);
            sessionStorage.setItem('displayed', 'true');
        });
    }
}

$(window).on('load', popupDisplay);