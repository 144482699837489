window.lineHeight = function () {
  var square = $( ".square" );
  var lastScrollTop = 0;
  var lastLineHeight = 0;
  if (square.length) {
    var offsetFirst = $( ".square" ).first().offset().top;
    var offsetLast = $( ".square" ).last().offset().top;
    var wHeight = ($(window).height() / 2) + 30;
    var maxHeight = offsetLast - offsetFirst;
    $('.line').css('max-height', maxHeight);
    $(window).on('scroll', function(){
      var scroll = $(window).scrollTop();
      if (scroll > lastScrollTop && (scroll + wHeight) > offsetFirst) {
        var lineHeight = (scroll + wHeight) - offsetFirst;
        if(lineHeight > lastLineHeight) {
          $('.line').css('height',  lineHeight);
          lastLineHeight = lineHeight;
        }
        
      }
      lastScrollTop = scroll;
    });
    
    //var borderHeight = offsetLast - offsetFirst;
    // $('.line').css('height', borderHeight);
  }
  
  
};

$(window).on('resize', window.lineHeight);